<script lang="ts">
	import ChevronDown from 'lucide-svelte/icons/chevron-down';
	import { accordion } from '../accordion';

	export let question: string;
</script>

<details class="border-b border-slate-200 group" use:accordion>
	<summary class="pb-3 w-full flex justify-between cursor-pointer">
		<h3 class="text-lg font-medium text-left">
			{question}
		</h3>

		<ChevronDown class="size-6 transition-transform duration-300 group-open:rotate-180" />
	</summary>

	<div class="pb-5 text-sm space-y-2">
		<slot />
	</div>
</details>
